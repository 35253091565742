import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { Button, Form } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import axios from 'axios'
import Message from '../components/Message'
import ScgCard from '../components/ScgCard'

const ConferenceCreateScreen = () => {
  const warningColor = '#c15900'
  const successColor = 'green'

  const navigate = useNavigate()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  }

  const [confName, setConfName] = useState('')
  const [confPrefix, setConfPrefix] = useState('')
  const [supportsPayments, setSupportsPayments] = useState(false)
  const [stripePriceId, setStripePriceId] = useState('')
  const [currency, setCurrency] = useState('usd')
  const [messageText, setMessageText] = useState('')
  const [messageVariant, setMessageVariant] = useState('')

  const resetFormValues = () => {
    setConfName('')
    setConfPrefix('')
    setStripePriceId('')
    setSupportsPayments(false)
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    const newConf = {
      name: confName,
      prefix: confPrefix,
      supportsPayments: supportsPayments,
      stripePriceId: stripePriceId,
      currency: currency,
    }
    if (supportsPayments && stripePriceId.trim() === '') {
      setMessageText(
        'You have not set the price ID for the posters. A paid conference can not have 0 as price ID per poster.'
      )
      setMessageVariant(warningColor)
      return
    }
    // GET RES STATUS
    try {
      const { data } = await axios.post('/api/conferences', newConf, config)
      setMessageText(
        `Successfully Added Conference with prefix: ${data.prefix}`
      )
      setMessageVariant(successColor)
      resetFormValues()
      setTimeout(() => {
        navigate(`/admin/conferencelist`)
      }, 500)
    } catch (error) {
      const status = error.response.status
      if (status === 422) {
        setMessageText(
          `Conference with prefix: ${newConf.prefix} already exists`
        )
        setMessageVariant(warningColor)
      }

      if (status === 500) {
        setMessageText('Something went wrong')
        setMessageVariant('red')
      }
    }
  }

  return (
    <ScgCard>
      <Message text={messageText} variant={messageVariant} />
      <FormContainer>
        <h1>Add new Conference</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group className='py-3' controlId='name'>
            <Form.Label>Conference Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter name'
              required
              value={confName}
              onChange={(e) => setConfName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group className='py-3' controlId='prefix'>
            <Form.Label>Conference Prefix</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter prefix'
              required
              value={confPrefix}
              onChange={(e) => setConfPrefix(e.target.value.trim())}
            ></Form.Control>
          </Form.Group>
          <Form.Group className='py-3' controlId='payment'>
            <Form.Label>Conference Payments</Form.Label>
            <Form.Check
              type='switch'
              id='custom-switch'
              value={supportsPayments}
              onChange={(e) => setSupportsPayments(e.target.checked)}
            />
          </Form.Group>

          <Form.Group
            className='py-3'
            controlId='price'
            style={{ display: supportsPayments ? 'block' : 'none' }}
          >
            <Form.Label>Currency</Form.Label>
            <Form.Select
              label='currency'
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option value={'usd'}>Dollars - $</option>
              <option value={'eur'}>Euros - €</option>
              <option value={'gbp'}>British Pound - £</option>
            </Form.Select>
          </Form.Group>
          <Form.Group
            className='py-3'
            controlId='price'
            style={{ display: supportsPayments ? 'block' : 'none' }}
          >
            <Form.Label>Stripe Price ID</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter stripe price id'
              value={stripePriceId}
              onChange={(e) => setStripePriceId(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <div className='buttonContainer'>
            <LinkContainer to={`/admin/conferencelist/`}>
              <Button className='buttonSecondary'>
                Back to Conference List
              </Button>
            </LinkContainer>
            <Button type='submit' className='buttonCta'>
              Add Conference
            </Button>
          </div>
        </Form>
      </FormContainer>
    </ScgCard>
  )
}

export default ConferenceCreateScreen
