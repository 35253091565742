import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Container } from 'react-bootstrap'
import Footer from './components/Footer'
import Header from './components/Header'
import HomeScreen from './screens/HomeScreen'
import LoginScreen from './screens/LoginScreen'
import UserListScreen from './screens/UserListScreen'
import UserCreateScreen from './screens/UserCreateScreen'
import UserUpdateScreen from './screens/UserUpdateScreen'
import { ProtectedRoute, AdminProtectedRoute } from './components/AuthRoutes'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import NotFoundScreen from './screens/NotFoundScreen'
import Sidebar from './components/Sidebar'
import LogoutScreen from './screens/LogoutScreen'
import PostersAnalyticsScreen from './screens/analytics/PostersAnalyticsScreen'
import AuthorsAnalyticsScreen from './screens/analytics/AuthorsAnalyticsScreen'
import TopicsAnalyticsScreen from './screens/analytics/TopicsAnalyticsScreen'
import AffiliationsAnalyticsScreen from './screens/analytics/AffiliationsAnalyticsScreen'
import VideosAnalyticsScreen from './screens/analytics/VideosAnalyticsScreen'
import AudiosAnalyticsScreen from './screens/analytics/AudiosAnalyticsScreen'
import TrafficAnalyticsScreen from './screens/analytics/TrafficAnalyticsScreen'
import ConferenceListScreen from './screens/ConferenceListScreen'
import ConferenceCreateScreen from './screens/ConferenceCreateScreen'
import ConferenceEditScreen from './screens/ConferenceEditScreen'
import SubmissionCodeRetrievalScreen from './screens/SubmissionCodeRetrievalScreen'
import SubmissionCodePaymentScreen from './screens/SubmissionCodePaymentScreen'
import AfterPaymentScreen from './screens/AfterPaymentScreen'
import SubmissionCodePaymentsListScreen from './screens/SubmissionCodePaymentsListScreen'
import { selectUserConfernce } from './actions/userActions'

const App = () => {
  const dispatch = useDispatch()

  const userSelectedConfernce = useSelector(
    (state) => state.userSelectedConfernce
  )
  const { selectedConference } = userSelectedConfernce

  const checkLocalStorage = (fetchedConferences) => {
    const localStorageSelectedConference = JSON.parse(
      localStorage.getItem('selectedConference')
    )
    // IF USER HAS NOT A SELECTED NAVIGATE HIM HOME
    if (!localStorageSelectedConference) {
    } else if (
      // IF USER HAS SELECTED CONF IN LOCAL STORAGE FROM PREVIOUS VISIT SET THAT ONE AS THE INITIAL
      localStorageSelectedConference &&
      selectedConference === undefined
    ) {
      dispatch(
        selectUserConfernce(localStorageSelectedConference.selectedConference)
      )
    }
  }

  useEffect(() => {
    checkLocalStorage()
  })

  return (
    <div className='App'>
      <Router>
        <Sidebar />
        <div className='mainContainer'>
          <Routes>
            <Route path='/login' element={<LoginScreen />} />
            <Route path='/logout' element={<LogoutScreen />} />
            <Route
              path='/analytics/posters/:prefix'
              element={
                <ProtectedRoute redirectTo='/login'>
                  <PostersAnalyticsScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path='/analytics/authors/:prefix'
              element={
                <ProtectedRoute redirectTo='/login'>
                  <AuthorsAnalyticsScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path='/analytics/topics/:prefix'
              element={
                <ProtectedRoute redirectTo='/login'>
                  <TopicsAnalyticsScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path='/analytics/affiliations/:prefix'
              element={
                <ProtectedRoute redirectTo='/login'>
                  <AffiliationsAnalyticsScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path='/analytics/videos/:prefix'
              element={
                <ProtectedRoute redirectTo='/login'>
                  <VideosAnalyticsScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path='/analytics/audios/:prefix'
              element={
                <ProtectedRoute redirectTo='/login'>
                  <AudiosAnalyticsScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path='/analytics/traffic/:prefix'
              element={
                <ProtectedRoute redirectTo='/login'>
                  <TrafficAnalyticsScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path='/admin/create'
              element={
                <AdminProtectedRoute redirectTo='/login'>
                  <UserCreateScreen />
                </AdminProtectedRoute>
              }
            />
            <Route
              path='/admin/conferencelist'
              element={
                <AdminProtectedRoute redirectTo='/login'>
                  <ConferenceListScreen />
                </AdminProtectedRoute>
              }
            />
            <Route
              path='/admin/conference/add'
              element={
                <AdminProtectedRoute redirectTo='/login'>
                  <ConferenceCreateScreen />
                </AdminProtectedRoute>
              }
            />
            <Route
              path='/admin/conference/edit/:prefix'
              element={
                <AdminProtectedRoute redirectTo='/login'>
                  <ConferenceEditScreen />
                </AdminProtectedRoute>
              }
            />
            <Route
              path='/admin/user/:id/edit'
              element={
                <AdminProtectedRoute redirectTo='/login'>
                  <UserUpdateScreen />
                </AdminProtectedRoute>
              }
            />
            <Route
              path='/admin/userlist'
              element={
                <AdminProtectedRoute redirectTo='/login'>
                  <UserListScreen />
                </AdminProtectedRoute>
              }
            ></Route>
            <Route
              path='/'
              element={
                <ProtectedRoute redirectTo='/login'>
                  <HomeScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path='/analytics/home'
              element={
                <ProtectedRoute redirectTo='/login'>
                  <HomeScreen />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path='/submissioncodes/:prefix'
              element={<SubmissionCodeRetrievalScreen />}
            ></Route>
            <Route
              path='/submissioncodes/payments/:prefix'
              element={<SubmissionCodePaymentScreen />}
            ></Route>
            <Route
              path='/submissioncodes/payments/:prefix/:success/'
              element={<AfterPaymentScreen />}
            ></Route>
            <Route path='/*' element={<NotFoundScreen />}></Route>
            <Route
              path='/admin/conference/:prefix/paymentslist'
              element={
                <AdminProtectedRoute redirectTo='/login'>
                  <SubmissionCodePaymentsListScreen />
                </AdminProtectedRoute>
              }
            />
          </Routes>
        </div>
        {/* <Footer /> */}
      </Router>
    </div>
  )
}

export default App
