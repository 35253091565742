import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  let items = [];
  
  // Previous button
  items.push(
    <Pagination.Prev 
      key="prev"
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
    />
  );

  // First page
  items.push(
    <Pagination.Item
      key={1}
      active={currentPage === 1}
      onClick={() => onPageChange(1)}
    >
      1
    </Pagination.Item>
  );

  if (currentPage > 3) {
    items.push(<Pagination.Ellipsis key="ellipsis1" disabled />);
  }

  // Middle pages
  for (let number = Math.max(2, currentPage - 1); number <= Math.min(totalPages - 1, currentPage + 1); number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => onPageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  if (currentPage < totalPages - 2) {
    items.push(<Pagination.Ellipsis key="ellipsis2" disabled />);
  }

  // Last page
  if (totalPages > 1) {
    items.push(
      <Pagination.Item
        key={totalPages}
        active={currentPage === totalPages}
        onClick={() => onPageChange(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    );
  }

  // Next button
  items.push(
    <Pagination.Next
      key="next"
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
    />
  );

  return (
    <div className="d-flex justify-content-center mt-3">
      <Pagination>{items}</Pagination>
    </div>
  );
};

export default PaginationComponent;