import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import axios from 'axios';
import Searchbar from '../components/Searchbar';
import PaginationComponent from '../components/Pagination';

const ConferenceListScreen = () => {
  const [conferences, setConferences] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const conferencesPerPage = 10;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const deleteConferenceHandler = async (id, confPrefix) => {
    if (window.confirm(`Are you sure you want to delete Conference: ${confPrefix}?`)) {
      try {
        await axios.delete(`/api/conferences/${id}`, config);
        setConferences(conferences.filter((conf) => conf._id !== id));
      } catch (error) {
        console.error('Error deleting conference:', error);
      }
    }
  };

  const getConferences = async () => {
    try {
      const { data } = await axios.get('/api/conferences/', config);
      setConferences(data.conferences);
    } catch (error) {
      console.error('Error fetching conferences:', error);
    }
  };

  useEffect(() => {
    getConferences();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const filteredConferences = conferences.filter((conference) => conference.prefix.toLowerCase().includes(search.trim().toLowerCase()));

  const indexOfLastConference = currentPage * conferencesPerPage;
  const indexOfFirstConference = indexOfLastConference - conferencesPerPage;
  const currentConferences = filteredConferences.slice(indexOfFirstConference, indexOfLastConference);
  const totalPages = Math.max(1, Math.ceil(filteredConferences.length / conferencesPerPage));

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className='p-3 m-3' style={{ backgroundColor: 'white', width: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
      <Row className='align-items-center mb-3'>
        <Col>
          <h1>Conferences</h1>
        </Col>
        <Col>
          <LinkContainer to={`/admin/conference/add`} style={{ float: 'right' }}>
            <Button className='buttonCta'>
              <i className='fas fa-plus'></i> Create Conference
            </Button>
          </LinkContainer>
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col>
          <Searchbar value={search} placeholder='Search by prefix' onChange={(e) => setSearch(e.target.value)} />
        </Col>
      </Row>

      <div style={{ flex: '1 1 auto', minHeight: '400px', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: '1 1 auto', overflowY: 'auto' }}>
          <Table bordered hover responsive className='table-sm mb-0'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Prefix</th>
                <th>Supports Payments</th>
                <th>Actions</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentConferences.map((conference) => (
                <tr key={conference._id}>
                  <td>{conference.name}</td>
                  <td>{conference.prefix}</td>
                  <td>
                    {conference.supportsPayments ? (
                      <LinkContainer to={`/admin/conference/${conference.prefix}/paymentslist`}>
                        <Button className='btn-sm buttonCta'>View Payments</Button>
                      </LinkContainer>
                    ) : (
                      <i className='fas fa-minus'></i>
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/admin/conference/edit/${conference.prefix}`}>
                      <Button variant='warning' className='btn-sm me-2'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button variant='danger' className='btn-sm' onClick={() => deleteConferenceHandler(conference._id, conference.prefix)}>
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                  <td>
                    <LinkContainer to={`/submissioncodes/${conference.prefix}`}>
                      <Button className='btn-sm buttonSecondary'>Go to</Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}

            </tbody>
          </Table>
        </div>

        {filteredConferences.length === 0 && (
          <div className='text-center py-4'>
            <p>No conferences found</p>
          </div>
        )}

        <div style={{ marginTop: '20px', paddingTop: '20px', borderTop: '1px solid #dee2e6' }}>
          <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
      </div>
    </div>
  );
};

export default ConferenceListScreen;
