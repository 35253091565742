import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useStripe } from '@stripe/react-stripe-js';
import FormContainer from '../components/FormContainer';
import { Button, Form, Spinner } from 'react-bootstrap';
import Message from '../components/Message';
import classes from './SubmissionCodePaymentScreen.module.css';
import Loader from '../components/Loader';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const SubmissionCodePaymentScreen = () => {
  const navigate = useNavigate();
  let params = useParams();
  const stripe = useStripe();
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const [email, setEmail] = useState('');
  const [sentRequest, setSentRequest] = useState(false);
  const [multipleUnpaidCodes, setMultipleUnpaidCodes] = useState(0);
  const [multiplePaidCodes, setMultiplePaidCodes] = useState(0);
  const [confName, setConfName] = useState('');
  const [abstractCodes, setAbstractCodes] = useState([]);
  const [fetchCodesLoading, setFetchCodesLoading] = useState(false);
  const [messageVariant, setMessageVariant] = useState('');
  const [messageText, setMessageText] = useState('');

  const emailFromQueryString = new URLSearchParams(window.location.search).get('email');

  const goToStripeCheckout = async (sessionId) => {
    const checkoutResponse = await stripe.redirectToCheckout({
      sessionId,
    });

    console.log(checkoutResponse);
  };

  const getConferenceDetails = async () => {
    try {
      const { data: conference } = await axios.get(`/api/conferences/${params.prefix}`);
      setConfName(conference.name);
      if (conference.supportsPayments === false) {
        navigate(`/submissioncodes/${conference.prefix}`);
      }
    } catch (error) {
      const status = error.response.status;
      if (status === 404) {
        navigate('/404');
      }
    }
  };

  const fetchAbstractCodes = async (authorEmail) => {
    // fetch codes from backend paid or not paid
    setFetchCodesLoading(true);
    try {
      const { data } = await axios.get(`/api/submissioncodes/fetch/${authorEmail}/${params.prefix}`);
      if (data.length === 0) {
        setMessageText(
          <span>
            We couldn't find any data associated with this email address. Please double-check your entry. If the email is correct and you're still
            having issues, contact us at <a href='mailto:support@scigentech.com'>support@scigentech.com</a>.
          </span>
        );
        setMessageVariant('orange');
      }
      setAbstractCodes([...data]);
      setMultipleUnpaidCodes([...data].filter((code) => code.paid === false).length > 1);
      setMultiplePaidCodes([...data].filter((code) => code.paid === true).length > 0);
      setFetchCodesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cardSubmitHandler = (e) => {
    e.preventDefault();
    setMessageText('');
    setMessageVariant('');
    fetchAbstractCodes(email);
  };

  const buyOneHandler = async (abstractId, authorEmail, prefix) => {
    try {
      const {
        data: { sessionId },
      } = await axios.post(`/api/stripe/create-checkout-session`, { abstractId, authorEmail, prefix, buyAll: false }, config);
      goToStripeCheckout(sessionId);
    } catch (error) {
      const status = error.response.status;
      if (status === 400) {
        setMessageText(
          <span>
            Sorry something went wrong. Please try refreshing the page. If the problem still exists please contact us at{' '}
            <a href='mailto:support@scigentech.com'>support@scigentech.com</a>.
          </span>
        );
        setMessageVariant('red');
      }
    }
  };

  const buyAllHandler = async (authorEmail, prefix) => {
    try {
      const {
        data: { sessionId },
      } = await axios.post(`/api/stripe/create-checkout-session`, { authorEmail, prefix, buyAll: true }, config);
      goToStripeCheckout(sessionId);
    } catch (error) {
      const status = error.response.status;
      if (status === 400) {
        setMessageText(
          <span>
            Sorry something went wrong. Please try refreshing the page. If the problem still exists please contact us at{' '}
            <a href='mailto:support@scigentech.com'>support@scigentech.com</a>.
          </span>
        );
        setMessageVariant('red');
      }
    }
  };

  const recallAbstractCodesHandler = async (authorEmail, prefix) => {
    try {
      const { status } = await axios.get(`/api/submissioncodes/email/${authorEmail}/${params.prefix}`);

      if (status === 200) {
        setMessageText(`An email has been sent to ${authorEmail}`);
        setMessageVariant('green');
      }
    } catch (error) {
      const status = error.response.status;
      if (status === 500) {
        setMessageText('Something went wrong');
        setMessageVariant('red');
      }
      console.log(error);
    }
  };

  useEffect(() => {
    getConferenceDetails();
  }, []);

  useEffect(() => {
    if (emailFromQueryString) {
      setEmail(emailFromQueryString);
      fetchAbstractCodes(emailFromQueryString);
    }
  }, [emailFromQueryString]);

  return (
    <div className={classes.customContainer}>
      {fetchCodesLoading ? (
        <Loader />
      ) : abstractCodes.length !== 0 ? (
        <div className={classes.abstractCodesContainer}>
          <Message text={messageText} variant={messageVariant} className='my-3' />
          <p style={{ textAlign: 'center', fontSize: '20px' }}>Your ePoster Submissions for {confName}</p>
          <p style={{ textAlign: 'center', fontSize: '14px' }}>
            To access your submission code(s), please complete the payment(s). If you have more than one poster, you may choose to buy all at once.
          </p>
          <div
            style={{
              color: '#7787ab',
              backgroundColor: '#F7F8FC',
              margin: '0',
              boxShadow: '0 0 0 0 #ffffff !important',
            }}
            className={classes.abstractItemContainer}
          >
            {/* <div className={classes.abstractIdContainer}>Abstract Id</div> */}
            <div className={classes.abstractTitleContainer}>Title</div>
            <div className={classes.abstractPayContainer}>Buy/Paid</div>
          </div>
          {abstractCodes.map((abstractCode) => (
            <div className={classes.abstractItemContainer} key={abstractCode.abstractId}>
              {/* <div className={classes.abstractIdContainer}>
                {abstractCode.abstractId}
              </div> */}
              <div style={{ width: '100%' }}>
                <div
                  className={classes.abstractTitleContainer}
                  dangerouslySetInnerHTML={{
                    __html: abstractCode.abstractTitle,
                  }}
                >
                  {/* {abstractCode.abstractTitle} */}
                </div>
              </div>
              <div className={classes.abstractPayContainer}>
                {abstractCode.paid ? (
                  <div className={classes.paid}>
                    <CheckBoxIcon style={{ color: '#97D12F' }} />
                  </div>
                ) : (
                  <Button
                    title='Complete payment to receive submission code for this poster.'
                    style={{ textTransform: 'none' }}
                    className={classes.buyButton}
                    onClick={() => buyOneHandler(abstractCode.abstractId, email, params.prefix)}
                  >
                    Buy
                  </Button>
                )}
              </div>
            </div>
          ))}
          <div className={classes.ctaButtonContainer}>
            <Button
              type='button'
              className={classes.buyButton}
              style={{ textTransform: 'none' }}
              onClick={() => {
                setAbstractCodes([]);
                setMessageText('');
              }}
            >
              Go Back
            </Button>
            {multipleUnpaidCodes && (
              <Button
                title='Complete payment to receive submission codes for all listed posters.'
                className={classes.buyButton}
                style={{ textTransform: 'none' }}
                onClick={() => buyAllHandler(email, params.prefix)}
              >
                Pay for All
              </Button>
            )}
            {multiplePaidCodes && (
              <Button
                className={classes.buyButton}
                style={{ textTransform: 'none' }}
                onClick={() => recallAbstractCodesHandler(email, params.prefix)}
              >
                Send Codes
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className={classes.cardContainer}>
          <div className={classes.flexItemLeft}>
            <p>Payment Required to access your ePoster submission code(s)</p>
            <div>
              <p>
                Enter your email address to find your submission code(s) for this conference. You will be required to complete payment to access them.
              </p>
            </div>
          </div>
          <div className={classes.flexItemRight}>
            <div className={classes.emailLabel}>
              Enter the email address used during your
              <br /> abstract submission to <strong>{confName}</strong>.
            </div>
            <FormContainer>
              <Form onSubmit={cardSubmitHandler}>
                <Form.Group controlId='email'>
                  <Form.Label style={{ display: 'none' }}>
                    Enter the email address used during your abstract submission to <strong>{confName}</strong>.
                  </Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='johndoe@example.com'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <div className={classes.buttonContainer}>
                  <Button
                    type='submit'
                    className={classes.cta}
                    style={{ textTransform: 'none', minWidth: '100px' }} // Adjust the minWidth based on your longest text
                    disabled={!email.length || fetchCodesLoading}
                  >
                    {fetchCodesLoading ? (
                      <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                    ) : (
                      'Access submission code(s)'
                    )}
                  </Button>
                </div>
              </Form>
            </FormContainer>
            <Message text={messageText} variant={messageVariant} className='my-3' />
          </div>
        </div>
      )}
    </div>
  );
};

export default SubmissionCodePaymentScreen;
